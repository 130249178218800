<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-model="riro_key"
        type="password"
        outlined
        dense
        hide-details
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-textarea
        v-model="sql"
        rows="9"
        outlined
        dense
        hide-details
      ></v-textarea>
    </v-col>
    <v-col cols="12" class="text-right" v-if="riro_key && sql">
      <v-btn color="success" @click="runSql">Run</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "RunSql",
  data: () => ({
    riro_key: null,
    sql: null,
  }),
  methods: {
    async runSql() {
      if (!this.riro_key || !this.sql) {
        return false;
      }
      try {
        const { data } = await httpClient.post("/warehouse/v1/admin-riro-sql", {
          riro_key: this.riro_key,
          sql: this.sql,
        });
        this.$vToastify.success(JSON.stringify(data));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
